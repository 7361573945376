// Importing app modules
import AppAPI from 'modules/api';

// Importing app helper
import { bold, h3, keyValueTable, preventUndefined } from './common';

export const regionStatistics = ({ analysisBiomaterialIds, reference, regions, i18n }) => {
  return regions.map((region) => {
    const snps = reference.stats?.trioSnps.find(snp => snp.name === region.name);
    const regionBios = AppAPI.Biomaterial.byDesignation(region.biomaterials.filter(
      regionBio => analysisBiomaterialIds.includes(regionBio['biomaterial_id'])
    ));
    const detail = {
      [i18n('CHROMOSOME')]: { '23': 'X', '24': 'Y' }[region.chromosome] ?? region.chromosome,
      [i18n('BAND')]: region.band,
      [i18n('START')]: region['start_at'],
      [i18n('END')]: region['start_at'] + region.length,
      [i18n('SIZE')]: region.length,
    };
    const disease = {
      [i18n('DISORDER_TYPE')]: i18n(region['gene_type'], 'gene'),
      [i18n('MATERNAL_STATUS')]: i18n(regionBios?.mother?.status, 'gene'),
      [i18n('PATERNAL_STATUS')]: i18n(regionBios?.father?.status, 'gene'),
      [i18n('REFERENCE_STATUS')]: i18n(regionBios?.reference?.status, 'gene'),
    };
    const body = [
      [
        '',
        i18n('PLATFORM_SNPS'),
        i18n('MATERNAL_INFORMATIVE_SNPS'),
        i18n('PATERNAL_INFORMATIVE_SNPS'),
      ],
      ...['left', 'main', 'right'].map((field) => preventUndefined([
        bold(i18n(`${field}_REGION`)),
        snps?.total[field],
        snps?.maternal[field],
        snps?.paternal[field],
      ])),
    ];
    return [
      h3(region.name),
      {
        layout: 'noBorders',
        table: {
          body: [[
            keyValueTable(i18n('REGION_DETAILS'), detail),
            keyValueTable(i18n('DISEASE_STATUS'), disease),
            {
              stack: [h3(i18n('AVAILABLE_SNPS')), { style: 'table', table: { body } }]
            },
          ]],
          headerRows: 0,
          widths: ['22%', '33%', '45%']
        },
      },
    ];
  });
};
