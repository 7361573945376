// Importing react libs
import React from 'react';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';

// Importing Helix components
import Alerts from 'helix/ui/alerts';

// Importing modules
import Permission from 'modules/permission';

// Importing app components
import { RecentBeadchipsList } from 'components/plate/recentBeadchipList';
import { List as CaseList } from 'components/case';
import { List as OrganizationList } from 'components/organization';
import { Signed } from 'components/statistic';

// Importing container styles
import './index.scss';

// Exporting component
export const Dashboard = (props) => {
  const _displayDashboard = () => {
    const Language = useLanguage();
    const ITEM_PER_PAGE = 5;
    const reader = async () => {
      const res = await AppAPI.Log.alerts({
        limit: ITEM_PER_PAGE,
        action__in: [
          'case_data_upload_failed',
          'analysis_failed_embryos',
          'analysis_failed_trio',
          'analysis_completed',
          'login_failed',
          'sample_qc_failed',
          'user_locked_out',
        ]
      });
      if (res.error) return res;
      res.results = res.results?.map((log) => {
        const { action, logType, time } = AppAPI.Log.parseLog(log, Language);
        return {
          msg: log.payload?.msg || <span>{time} - {action}</span>,
          type: log.payload?.type || logType || 'info',
        };
      }) || [];
      return res;
    };

    return (
      <div className="dashboard">
        <h2>{Language.get('dashboard', 'ALERTS').toUpperCase()}</h2>
        <Alerts reader={reader} />
        <h2>{Language.get('dashboard', 'RECENT_CASES').toUpperCase()}</h2>
        <CaseList name="dashboard-cases" limit={ITEM_PER_PAGE} showMorePath='/case-list' />
        <h2>{Language.get('dashboard', 'RECENT_BEADCHIP_ARRAYS').toUpperCase()}</h2>
        <RecentBeadchipsList pageSize={ITEM_PER_PAGE} showMorePath='/beadchip' showPagination={false} />
        <h2>{Language.get('dashboard', 'STATISTICS').toUpperCase()}</h2>
        <Signed />
      </div>
    );
  };
  const isAdmin = Permission.isHostAdmin() || Permission.isSuperuser();
  return (
    <div className="organization-dashboard">
      {isAdmin ? <OrganizationList {...props} /> : _displayDashboard()}
    </div>
  );
};
