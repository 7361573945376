// Importing react libs
import React from 'react';

// Importing antd libs
import { Descriptions } from 'antd';
import 'antd/es/descriptions/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './header.scss';

// Defining constants
const { Item } = Descriptions;

const Header = ({ items }) => {
  return (
    <div className="header">
      <Descriptions>
        {items.map(item => <Item key={item.label} label={item.label}>{item.value}</Item>)}
      </Descriptions>
    </div>
  );
};

// Exporting component
export const CaseHeader = ({ caseID, subjectID }) => {
  const Language = useLanguage();
  return (
    <Header items={[
      { label: Language.get('case', 'CASE_ID'), value: caseID },
      { label: Language.get('case', 'MATERNAL_SAMPLE_ID'), value: subjectID }
    ]}
    />
  );
};

export const SampleDetailHeader = ({ internalId, sampleId }) => {
  const Language = useLanguage();
  return (
    <Header items={[
      { label: Language.get('sample', 'SAMPLE_ID'), value: sampleId },
      { label: Language.get('sample', 'INTERNAL_ID'), value: internalId }
    ]}
    />
  );
};
