import Abstract from './abstract';

class User extends Abstract {
  /**
   * Constructor
  **/
  constructor() {
    super();
    this.resource = 'user';
    this._updateEndpoint();
    this.authorization = 'Basic ' + btoa(`${process.env.REACT_APP_ID}:${process.env.REACT_APP_SECRET}`);
  }

  get isDev() {
    const { is_dev: isDev } = window.app?.user ?? {};
    return isDev;
  }

  get isMFARequired() {
    const {
      user: {
        organization: {
          setting: {
            MISCELLANEOUS: { ENABLE_MFA_FOR_ALL = false } = {}
          } = {}
        } = {},
      } = {}
    } = window.app ?? {};
    return ENABLE_MFA_FOR_ALL && !this.isDev;
  }

  get isMFASetup() {
    const {
      user: {
        is_mfa_authenticator_setup: isMFAAuthAppSetup = false,
        is_mfa_email_setup: isMFAEmailSetup = false
      } = {}
    } = window.app ?? {};
    return isMFAAuthAppSetup || isMFAEmailSetup;
  }

  /**
   * Method to return the user detail information using username
   * @param {string} username of the user
   * @returns {Object} with user detail information
  **/
  async byUsername(username, signal) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/by_username/?username=${encodeURIComponent(username)}`,
      {
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        credentials: 'include',
        method: 'GET',
        signal
      },
    );
    return this._handleError(res);
  }

  /**
   * get oauth from session
   * @param {Object} signal
   * @return {void}
  **/
  async fromSession(signal) {
    const username = this.OAuth.get('username');
    const res = await this.byUsername(username, signal);
    if (!res.error) {
      window.app.user = res;
      window.app.regions = [
        { 'name': 'REGION_1', 'latency': 234 },
        { 'name': 'REGION_2', 'latency': 324 },
      ];
    }
    return window.app?.user;
  }

  /**
   * get secrets for kmap upload api app
   * @param {void}
   * @return {void}
  **/
  async getAccess() {
    const res = await fetch(
      `${this.constants.ENDPOINT}/access/`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'GET',
      },
    );
    return this._handleError(res);
  }

  /**
   * Get QR code for MFA setup
   * @param {void}
   * @return {void}
  **/
  async getQRCode() {
    const res = await fetch(
      `${this.constants.ENDPOINT}/generate_qr_code/`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    return this._handleError(res);
  }

  /**
   * request oauth token by user credential
   * @param {void}
   * @return {void}
  **/
  async login({ username, password }) {
    const res = await fetch(
      `${window.BASE_API_URL}/admin-oauth/token/`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': this.authorization
        },
        method: 'POST',
        credentials: 'include',
        body: `grant_type=password&password=${encodeURIComponent(password)}&username=${encodeURIComponent(username)}`,
      },
    );
    const response = await this._handleError(res);
    response.username = username;
    if (!response.error) this.OAuth.set(response);
    return response;
  }

  /**
   * request oauth token to be erased
   * @param {void}
   * @return {Promise<void>}
  **/
  async logout() {
    const res = await fetch(
      `${window.BASE_API_URL}/admin-oauth/revoke_token/`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': this.authorization
        },
        body: `token=${this.OAuth.get('access_token')}`,
        credentials: 'include',
        method: 'POST',
      },
    );
    const response = await this._handleError(res);
    window.app.user = undefined;
    this.OAuth.set({});
    document.cookie.split(';').forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    return response;
  }

  /**
   * request recover password
   * @param {void}
   * @return {void}
  **/
  async recover(email) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/recover/`,
      {
        headers: {
          'Content-Type': 'application/json;',
          'Authorization': this.authorization
        },
        method: 'POST',
        body: JSON.stringify({ email }),
      },
    );
    return this._handleError(res);
  }

  /**
   * request oauth token by user credential
   * @param {void}
   * @return {void}
  **/
  async refreshToken() {
    const refreshToken = this.OAuth.get('refresh_token');
    const res = await fetch(
      `${window.BASE_API_URL}/admin-oauth/token/`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': this.authorization
        },
        method: 'POST',
        credentials: 'include',
        body: `grant_type=refresh_token&refresh_token=${encodeURIComponent(refreshToken)}`,
      },
    );
    const response = await this._handleError(res);
    response.username = window.app.user.username;
    if (!response.error) this.OAuth.set(response);
    return response;
  }

  /**
   * Remove MFA type for user
   * @param {Object} data
   * @returns {void}
  **/
  async removeMFA(data) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/remove_mfa/`,
      {
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    );
    return this._handleError(res);
  }

  /**
   * Reset all MFA for user
   * @param {Object} data
   * @returns {void}
  **/
  async resetMFA(data) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/reset_mfa/`,
      {
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    );
    return this._handleError(res);
  }

  /**
   * Send MFA code
   * @param {void}
   * @return {void}
  **/
  async sendMFACode() {
    const res = await fetch(
      `${this.constants.ENDPOINT}/send_mfa_code/`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    return this._handleError(res);
  }


  /**
   * Send MFA code for setup
   * @param {void}
   * @return {void}
  **/
  async sendMFASetupCode() {
    const res = await fetch(
      `${this.constants.ENDPOINT}/send_mfa_setup_code/`,
      {
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    return this._handleError(res);
  }

  /**
   *
   * @param {boolean} agree
   * @returns {Object} response from the API
   */
  async signTerms(agree) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/${window.app.user.id}/sign_terms/`,
      {
        headers: {
          ...this.header(),
          'Content-Type': 'application/json;',
        },
        method: 'PUT',
        body: JSON.stringify({ agree })
      },
    );
    return this._handleError(res);
  }

  /**
   * validate sesame token and create access token
   * @param {void}
   * @return {void}
  **/
  async validateToken(data) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/validate_token/`,
      {
        headers: {
          'Content-Type': 'application/json;',
          'Authorization': this.authorization
        },
        method: 'POST',
        body: JSON.stringify(data),
      },
    );
    const response = await this._handleError(res);
    if (!response.error) this.OAuth.set(response);
    return response;
  }

  /**
   * Verify MFA code
   * @param {Object} data
   * @returns {void}
  **/
  async verifyMFACode(data) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/verify_mfa/`,
      {
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    );
    return this._handleError(res);
  }

  /**
   * Verify MFA setup code
   * @param {Object} data
   * @returns {void}
  **/
  async verifyMFASetupCode(data) {
    const res = await fetch(
      `${this.constants.ENDPOINT}/verify_mfa_setup/`,
      {
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
          ...this.header(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    );
    return this._handleError(res);
  }

  /**
   *
   * @returns {Number} milliseconds after which the oauth token will expire
   */
  sessionExpiresIn() {
    const expiresAt = Number(window.localStorage.getItem(this.OAuth.EXPIRES_AT_KEY));
    const now = new Date().getTime();
    return expiresAt - now;
  }

  /**
 * Helper to update the endpoint if base api url changed
 * @param {void}
 * @return {void}
**/
  _updateEndpoint() {
    this.constants.ENDPOINT = `${this.constants.API_URL}/${this.constants.VERSION}/${this.resource}`;
    this.constants.AVATAR = `${this.constants.ENDPOINT}/avatar/`;
  }
}

export default new User();
