// Importing react libs
import React, { useState } from 'react';

// Importing antd libs
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import { Alert, Button, Col, Row, Space, Spin } from 'antd';
import 'antd/es/alert/style/css';
import 'antd/es/button/style/css';
import 'antd/es/col/style/css';
import 'antd/es/row/style/css';
import 'antd/es/space/style/css';
import 'antd/es/spin/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix components

// Importing app components
import SampleDetails from 'components/case/view/details';
import ChromNav from 'components/case/view/navigation/chromNav';
import { Meta } from 'components/analysis/meta';
import { SampleDetailHeader } from 'components/case/view/header';

import './sample.scss';

export const Sample = ({
  analysis,
  analysisId,
  analysisChartFiles,
  analysisChartFilesLoading,
  caseId,
  caseObj,
  chromosome,
  reference,
  sampleId,
  samples = [],
  onNavigation,
}) => {
  const Language = useLanguage();
  const [isFullGenome, setIsFullGenome] = useState(false);

  const currentSampleIdx = samples.findIndex(
    (sample) => sample.id === Number.parseInt(sampleId)
  );

  const biomaterial = samples[currentSampleIdx];
  const designation = biomaterial?.['biomaterial_designation'];

  const handleNextClick = () => {
    const nextIndex = currentSampleIdx + 1;
    if (nextIndex < samples.length) {
      onNavigation({
        sampleId: samples[nextIndex].id,
        chromosome,
      });
    }
  };

  const handlePrevClick = () => {
    const prevIndex = currentSampleIdx - 1;
    if (prevIndex > -1) {
      onNavigation({
        sampleId: samples[prevIndex].id,
        chromosome,
      });
    }
  };

  let sampleChartFiles = {};

  if (designation) {
    const trioAnalysisId = analysis['bp_trio_analysis_id'];
    const uiFiles = analysisChartFiles[trioAnalysisId];
    if (uiFiles) {
      sampleChartFiles['baf_logr'] = uiFiles[`${designation}_baf_logr`];
      sampleChartFiles['haploblocks'] = uiFiles[`${designation}_haploblocks`];
    }
  } else {
    sampleChartFiles = analysisChartFiles[biomaterial?.['bp_analysis_id']] ?? {};
  }

  const sampleName = samples[currentSampleIdx]?.['biomaterial_name'];

  return (
    <div>
      <SampleDetailHeader internalId={sampleId} sampleId={sampleName} />
      <Row className="case-sample-header" gutter={16} justify="space-between">
        <Col className="case-sample-navigation">
          <Space>
            <Space.Compact>
              <Button
                type="primary"
                icon={<CaretLeftFilled />}
                onClick={handlePrevClick}
                disabled={currentSampleIdx === 0}
              >
                {Language.get('common', 'PREVIOUS')}
              </Button>
              <Button
                type="primary"
                onClick={handleNextClick}
                disabled={currentSampleIdx === samples.length - 1}
              >
                {Language.get('common', 'NEXT')}
                <CaretRightFilled />
              </Button>
            </Space.Compact>
          </Space>
        </Col>
        <Col flex="auto">
          {biomaterial && <Meta biomaterial={biomaterial} />}
        </Col>
      </Row>
      {Object.keys(sampleChartFiles).length > 0 ? (
        <SampleDetails
          caseObj={caseObj}
          chrom={chromosome}
          chromNav={(
            <ChromNav
              isFullGenome={isFullGenome}
              path={`/case/${caseId}/analysis/${analysisId}/sample/${sampleId}`}
              onClick={(chromPath) => {
                const chrom = chromPath.split('chromosome/')?.[1] ?? '1';
                setIsFullGenome(false);
                onNavigation({ sampleId, chromosome: chrom });
              }}
              onClickFullGenome={() => setIsFullGenome(curr => !curr)}
            />
          )}
          reference={reference}
          sample={biomaterial}
          sampleChartFiles={sampleChartFiles}
          isFullGenome={isFullGenome}
        />
      ) : analysisChartFilesLoading ? <Spin /> :
        <Alert type="info" message={Language.get('case', 'NO_SAMPLE_FILES')} />
      }
    </div>
  );
};
