// Importing React and external libs
import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';

// Importing antd
import { Button, Input, message } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing modules
import AppAPI from 'modules/api';

// Importing CSS
import './appAuthenticator.scss';

// Constants
const AUTH_APP = 'authenticator_app';
const MAX_MFA_CODE_INPUT = 6;


const AppAuthenticator = () => {
  const Language = useLanguage();
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [code, setCode] = useState('');
  const [isSettingUp, setIsSettingUp] = useState(false);

  const {
    user: {
      is_mfa_authenticator_setup: isMFAAuthAppSetup = false,
    } = {}
  } = window.app ?? {};

  useEffect(() => {
    if (isSettingUp) {
      fetchQRCode();
    }
  }, [isSettingUp]);

  const fetchQRCode = async () => {
    const res = await AppAPI.User.getQRCode();
    if (res.error) {
      message.error(Language.get('user', res.msg));
      setIsSettingUp(false);
    } else {
      setQrCodeUrl(res.qrcode);
    }
  };

  const handleVerifyMFASetup = async () => {
    const res = await AppAPI.User.verifyMFASetupCode({ code, auth_type: AUTH_APP });
    handleResponse('MFA_CODE_VERIFIED_SUCCESS', res);
  };

  const handleRemoveMFA = async () => {
    const res = await AppAPI.User.removeMFA({ auth_type: AUTH_APP });
    handleResponse('MFA_REMOVED_SUCCESS', res);
  };

  const handleResponse = (successMessage, response) => {
    if (response.error) {
      message.error(Language.get('user', response.msg));
    } else {
      message.success(Language.get('user', successMessage));
      window.location.reload();
    }
  };

  const renderQRCodeSetup = () => (
    <div className="auth-verify">
      <p>{Language.get('user', 'SCAN_QR_CODE')}</p>
      <QRCodeSVG value={qrCodeUrl} />
      <div className="validate-code">
        <Input
          className="code-input"
          placeholder="Enter code"
          maxLength={MAX_MFA_CODE_INPUT}
          size="small"
          onChange={(event) => setCode(event.target.value)}
        />
        <div className="button-group">
          <Button size="small" type="primary" onClick={handleVerifyMFASetup}>
            {Language.get('user', 'VERIFY')}
          </Button>
          <Button size="small" type="primary" onClick={() => setIsSettingUp(false)}>
            {Language.get('common', 'CANCEL')}
          </Button>
        </div>
      </div>
    </div>
  );

  const renderAuthStatus = () => (
    isMFAAuthAppSetup ? (
      <div className="auth-enabled">
        <div>
          {Language.get('common', 'ENABLED')} <CheckCircleTwoTone twoToneColor="#52c41a" />
        </div>
        <Button
          icon={<DeleteTwoTone />}
          size="small"
          onClick={handleRemoveMFA}
        />
      </div>
    ) : (
      <div className="auth-disabled">
        <div>
          {Language.get('common', 'NOT_ENABLED')} <CloseCircleTwoTone twoToneColor="#A9A9A9" />
        </div>
        <Button
          icon={<EditTwoTone />}
          size="small"
          onClick={() => setIsSettingUp(true)}
        />
      </div>
    )
  );

  return (
    <div className="app-authenticator">
      <div className="app-auth">
        <div className="auth-label">{Language.get('user', 'MFA_AUTH_APP')}</div>
        {isSettingUp ? renderQRCodeSetup() : renderAuthStatus()}
      </div>
    </div>
  );
};

export default AppAuthenticator;
