// Importing react libs
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Importing antd libs
import { Alert, Button, Col, Popconfirm, Radio, Row, Space, Spin, Tabs } from 'antd';
import 'antd/es/alert/style/css';
import 'antd/es/button/style/css';
import 'antd/es/col/style/css';
import 'antd/es/popconfirm/style/css';
import 'antd/es/radio/style/css';
import 'antd/es/row/style/css';
import 'antd/es/space/style/css';
import 'antd/es/tabs/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app modules
import AppAPI from 'modules/api';
import Permission from 'modules/permission';

// Importing app helpers
import { Notification } from 'helper/handler/notification';

// Importing app components
import { Log } from 'components/case/view/log';
import { SignoffModal } from 'components/analysis/signoff/modal';
import { CaseHeader } from 'components/case/view/header';
import { Header as AnalysisHeader } from 'components/case/header';
import { Preview } from 'components/case/preview';
import { Sample } from 'components/case/view/sample';
import Overview from 'components/case/view/overview';
import SampleReport from 'components/case/view/sampleReport';

// Improting local hooks
import useChartFiles from 'components/case/view/useChartFiles';

// Importing component style
import './view.scss';

export const ANALYSIS_IN_PROGRESS_STATUSES = ['processing_trio', 'completed_trio', 'processing_embryos'];
export const ANALYSIS_RESULTS_AVAILABLE_STATUSES = ['completed_trio', 'processing_embryos', 'failed_embryos', 'completed'];
export const CASE_REFRESH_INCOMPLETE_MS = 1000 * 15;
export const CASE_REFRESH_COMPLETE_MS = false;

// Defining helper component
const renderTabBar = (analysis, loadCaseData, caseObj) => (props, DefaultTabBar) => {
  const Language = useLanguage();

  const STATUS_MAP = {
    'failed_embryos': 'error',
    'failed_trio': 'error',
    'completed_trio': 'info',
    'processing_trio': 'info',
    'processing_embryos': 'info',
  };

  const analysisStatus = analysis?.status ?? 'waiting';
  const alertType = STATUS_MAP[analysisStatus];

  return (
    <div>
      <DefaultTabBar {...props} />
      <AnalysisHeader analysis={analysis} loadCaseData={loadCaseData} caseObj={caseObj} />
      {alertType ? (
        <Fragment>
          <Alert
            showIcon
            type={alertType}
            message={Language.get('analysis', `ANALYSIS_STATUS_${analysisStatus.toUpperCase()}`)}
          />
          <br />
        </Fragment>
      ) : null}
      {analysis?.['is_stale'] && (
        <Fragment>
          <Alert
            showIcon
            type="warning"
            message={Language.get('analysis', 'ANALYSIS_STALE_WARNING')}
          />
          <br />
        </Fragment>
      )}
    </div>
  );
};

// Defining constants
const SAMPLE_DETAILS_TAB_KEY = 'sample-detail';
const hasActiveSignoff = (caseObj) => caseObj['signoff_history'].some((signoff) => signoff['is_active']);
const { Compact } = Space;
const { Group } = Radio;

// Exporting component
export const View = ({ caseObj: caseData, loadCaseData }) => {
  const [caseObj, setCaseObj] = useState(caseData);
  const { external_id: externalId, id: caseID, subject_id: subjectID, analyses } = caseObj;
  const caseLink = `/case/${caseID}`;
  const Language = useLanguage();
  const navigate = useNavigate();
  const initialChrom = caseObj.geneRegions?.[0]?.chromosome ?? '1';
  const {
    analysisId: analysisIdParam,
    chromosome: chromParam = initialChrom,
    sampleId: sampleIdParam
  } = useParams();
  const isCaseLocked = caseObj['is_lock'];
  const currentAnalysisIdx = analyses.findIndex(analysis => analysis.id === Number(analysisIdParam));
  const selectedAnalysisIdx = currentAnalysisIdx === -1 ? 0 : currentAnalysisIdx;
  const [isSendingLock, setIsSendingLock] = useState(false);
  const [isSendingSignoff, setIsSendingSignoff] = useState(false);
  const [showSignoffModal, setShowSignoffModal] = useState(false);
  const [navigationParams, setNavigationParams] = useState({
    chromosome: chromParam,
    sampleId: sampleIdParam,
  });
  const [activeTab, setActiveTab] = useState(sampleIdParam ? SAMPLE_DETAILS_TAB_KEY : 'overview');

  const currentAnalysis = analyses[selectedAnalysisIdx];
  const { id: analysisId, status } = currentAnalysis;
  const isProcessing = ANALYSIS_IN_PROGRESS_STATUSES.includes(status);
  const refetchInterval = isProcessing ? CASE_REFRESH_INCOMPLETE_MS : CASE_REFRESH_COMPLETE_MS;
  const areResultsAvailable = ANALYSIS_RESULTS_AVAILABLE_STATUSES.includes(status);

  const { data: analysisChartFiles = {}, isFetching } = useChartFiles(
    analysisId, { refetchInterval, enabled: areResultsAvailable }
  );
  const { father, mother, reference, embryos } = currentAnalysis.biomaterialsByDesignation ?? {};
  const biomaterialsInOrder = [father, mother, reference, ...(embryos ?? [])].filter(sample => Boolean(sample?.id));

  useEffect(() => {
    setNavigationParams((params) => ({ ...params, sampleId: sampleIdParam ?? params.sampleId }));
    setActiveTab(sampleIdParam ? SAMPLE_DETAILS_TAB_KEY : activeTab);
  }, [sampleIdParam]);

  useEffect(() => {
    if (activeTab === SAMPLE_DETAILS_TAB_KEY) {
      const validSampleId = biomaterialsInOrder.some(
        (material) => {
          return material.id?.toString() === navigationParams.sampleId?.toString();
        }
      );
      const sampleId = validSampleId
        ? navigationParams.sampleId
        : biomaterialsInOrder[0].id;
      if (sampleId) {
        navigate(
          `${caseLink}/analysis/${analysisId}/sample/${sampleId}/chromosome/${navigationParams.chromosome}`,
          { replace: true }
        );
      }
    } else {
      navigate(`${caseLink}/analysis/${analysisId}`, { replace: true });
    }
  }, [caseID, analysisId, activeTab, navigationParams, currentAnalysis]);

  const analysisOptions = analyses.map(({ id }) => ({
    label: `Analysis ${id}`,
    value: id,
  })).sort((a, b) => a.value - b.value);

  const { isLevel1, isLevel2 } = Permission;
  const isSignedOff = hasActiveSignoff(caseObj);
  const disableLocking = !(isLevel1() || isLevel2()) || isSignedOff;
  const disableSignoff = !(isCaseLocked && isLevel2());
  const disableCancelSignoff = !isLevel2();

  const handleToggleLock = async () => {
    setIsSendingLock(true);
    const res = await AppAPI.Case.save({
      id: caseID,
      is_lock: !isCaseLocked,
    });
    setIsSendingLock(false);

    if (res.error) return Notification.error(Language, res);
    setCaseObj(await loadCaseData());
  };

  const handleSignoffSent = async () => {
    setCaseObj(await loadCaseData());
    setShowSignoffModal(false);
  };

  const handleCancelSignOff = async () => {
    setIsSendingSignoff(true);
    const res = await AppAPI.Case.cancelSignoff(caseID);
    setCaseObj(await loadCaseData());
    setIsSendingSignoff(false);
    if (res.error) return Notification.error(Language, res);
  };

  const handleTabChange = useCallback((newActiveKey) => {
    setActiveTab(newActiveKey);
  }, []);

  const items = [
    {
      label: Language.get('case', 'OVERVIEW_TAB'),
      key: 'overview',
      children: areResultsAvailable && Object.keys(analysisChartFiles).length > 0 ? (
        <Overview
          analysis={currentAnalysis}
          analysisChartFiles={analysisChartFiles}
          chrom={navigationParams.chromosome}
          geneRegions={caseObj.geneRegions}
          setChrom={(chromosome) => setNavigationParams({
            chromosome,
            sampleId: sampleIdParam,
          })}
        />
      ) : isFetching ? <Spin /> : <Alert type="info" message={Language.get('case', 'NO_ANALYSIS_FILES')} />
    },
    {
      label: Language.get('case', 'SAMPLE_DETAIL_TAB'),
      key: 'sample-detail',
      children: (
        <Sample
          analysis={currentAnalysis}
          analysisId={analysisId}
          analysisChartFiles={analysisChartFiles}
          analysisChartFilesLoading={isFetching}
          caseId={caseID}
          caseObj={caseObj}
          chromosome={navigationParams.chromosome}
          reference={reference}
          samples={biomaterialsInOrder}
          sampleId={sampleIdParam}
          onNavigation={setNavigationParams}
        />
      ),
    },
    {
      label: Language.get('case', 'REPORT_SUMMARY_TAB'),
      key: 'report-summary',
      children: (
        <Preview caseObj={caseObj} analysis={currentAnalysis} />
      ),
    },
    {
      label: Language.get('case', 'REPORT_SAMPLES_TAB'),
      key: 'report-samples',
      children: (
        <SampleReport caseObj={caseObj} analysis={currentAnalysis} />
      ),
    },
    {
      label: Language.get('case', 'LOG_TAB'),
      key: 'log',
      children: (
        <Log analysis={currentAnalysis} />
      )
    },
  ];

  return (
    <div className="case-view">
      <Row align="top">
        <Col span={18}>
          <CaseHeader caseID={externalId} subjectID={subjectID} />
          <Group
            buttonStyle="solid"
            options={analysisOptions}
            optionType="button"
            value={analyses[selectedAnalysisIdx].id}
            onChange={(event) => navigate(`${caseLink}/analysis/${event.target.value}`)}
          />
        </Col>
        <Col span={6}>
          <Compact block style={{ justifyContent: 'flex-end' }}>
            <Button
              disabled={disableLocking}
              loading={isSendingLock}
              type="default"
              onClick={handleToggleLock}
            >
              {Language.get('case', isCaseLocked ? 'UNLOCK' : 'LOCK')}
            </Button>
            {isSignedOff ? (
              <Popconfirm
                title={Language.get('case', 'CANCEL_SIGN_OFF_CONFIRM')}
                onConfirm={() => isSignedOff ? handleCancelSignOff() : setShowSignoffModal(true)}
                okText={Language.get('common', 'YES')}
                cancelText={Language.get('common', 'NO')}
                disabled={disableCancelSignoff}
              >
                <Button
                  disabled={disableSignoff}
                  loading={isSendingSignoff}
                  type="primary"
                >
                  {Language.get('case', 'CANCEL_SIGN_OFF')}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                disabled={disableSignoff}
                loading={isSendingSignoff}
                type="primary"
                onClick={() => setShowSignoffModal(true)}
              >
                {Language.get('case', 'SIGN_OFF')}
              </Button>
            )}
          </Compact>
        </Col>
        <Col span={24}>
          <Tabs
            {...(activeTab ? { activeKey: activeTab } : {})}
            items={items}
            onChange={handleTabChange}
            renderTabBar={renderTabBar(currentAnalysis, loadCaseData, caseObj)}
            destroyInactiveTabPane={true}
          />
        </Col>
      </Row>
      <SignoffModal
        key={AppAPI.Case.formatSignoffHistory(caseObj['signoff_history'])}
        caseObj={caseObj}
        open={showSignoffModal}
        onClose={() => setShowSignoffModal(false)}
        onSignoffSent={handleSignoffSent}
      />
    </div >
  );
};
