// Importing React and external libs
import React, { useState } from 'react';

// Importing antd components
import { Button, Input, message } from 'antd';
import 'antd/es/input/style/css';
import 'antd/es/button/style/css';
import 'antd/es/message/style/css';

// Importing antd icons
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing modules
import AppAPI from 'modules/api';

// Importing CSS
import './emailAuthenticator.scss';

// Constants
const EMAIL = 'email';
const MAX_MFA_CODE_INPUT = 6;


const EmailAuthenticator = () => {
  const Language = useLanguage();
  const [code, setCode] = useState('');
  const [isSettingUp, setIsSettingUp] = useState(false);

  const {
    user: {
      is_mfa_email_setup: isMFAEmailSetup = false
    } = {}
  } = window.app ?? {};

  const handleResponse = (successMessage, response, errorCallback, reload = true) => {
    if (response.error) {
      message.error(Language.get('user', response.msg));
      if (errorCallback) errorCallback();
      return;
    }
    message.success(Language.get('user', successMessage));
    if (reload) window.location.reload();
  };

  const startSetup = async () => {
    setIsSettingUp(true);
    const response = await AppAPI.User.sendMFASetupCode();
    handleResponse('MFA_CODE_SENT_SUCCESS', response, () => setIsSettingUp(false), false);
  };

  const verifyMFASetup = async () => {
    const response = await AppAPI.User.verifyMFASetupCode({ code, auth_type: EMAIL });
    handleResponse('MFA_CODE_VERIFIED_SUCCESS', response);
  };

  const removeMFA = async () => {
    const response = await AppAPI.User.removeMFA({ auth_type: EMAIL });
    handleResponse('MFA_REMOVED_SUCCESS', response);
  };

  const renderSetupView = () => (
    <div className="auth-verify">
      <p>{Language.get('user', 'ENTER_CODE')}:</p>
      <div className="validate-code">
        <Input
          className="code-input"
          placeholder={Language.get('user', 'ENTER_CODE')}
          maxLength={MAX_MFA_CODE_INPUT}
          size="small"
          onChange={(e) => setCode(e.target.value)}
        />
        <div className="button-group">
          <Button size="small" type="primary" onClick={verifyMFASetup}>
            {Language.get('user', 'VERIFY')}
          </Button>
          <Button size="small" type="primary" onClick={() => setIsSettingUp(false)}>
            {Language.get('common', 'CANCEL')}
          </Button>
        </div>
      </div>
    </div>
  );

  const renderEnabledView = () => (
    <div className="auth-enabled">
      <div>
        {Language.get('common', 'ENABLED')} <CheckCircleTwoTone twoToneColor="#52c41a" />
      </div>
      <Button icon={<DeleteTwoTone />} size="small" onClick={removeMFA} />
    </div>
  );

  const renderDisabledView = () => (
    <div className="auth-disabled">
      <div>
        {Language.get('common', 'NOT_ENABLED')} <CloseCircleTwoTone twoToneColor="#A9A9A9" />
      </div>
      <Button icon={<EditTwoTone />} size="small" onClick={startSetup} />
    </div>
  );

  return (
    <div className="email-authenticator">
      <div className="email-auth">
        <div className="auth-label">{Language.get('user', 'MFA_EMAIL')}</div>
        {isSettingUp ? renderSetupView() : isMFAEmailSetup ? renderEnabledView() : renderDisabledView()}
      </div>
    </div>
  );
};

export default EmailAuthenticator;
