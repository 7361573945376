// Importing external libs
import { useQuery } from '@tanstack/react-query';

// Importing app modules
import AppAPI from 'modules/api';

/**
 *
 * @param {Stream} response of a fetch
 * @param {String} format of the file
 * @returns {String} list of urls
 */
const parser = async (fileResponse, type = 'json') => {
  // translate list of file into object
  if (type === 'txt') {
    return (await fileResponse.text()).split('\n').reduce((allPaths, filepath) => {
      // get the key for each file path
      const match = filepath.match(/\.(\w+)(.json|.tsv|.tsv.gz)$/);
      if (match && match[1]) {
        allPaths[match[1]] = `generate_embryo_data/${filepath}`;
      }
      return allPaths;
    }, {});
  } else {
    return await fileResponse.json();
  }
};

/**
 *
 * @param {String} file path
 * @param {String} format of the file
 * @returns {Promise<Array>} list of urls
 */
const readFile = async (uiFileListUrl, type = 'json') => {
  // initailize a map to hold the URLs of all UI files by key
  let uiFilesPaths = {};
  try {
    // get the JSON object which contains path of each ui file by key
    const fileResponse = await fetch(uiFileListUrl, {
      credentials: 'include',
      mode: 'cors'
    });
    uiFilesPaths = await parser(fileResponse, type);
  } catch (error) {
    uiFilesPaths = {};
  }
  const uiFileBaseUrlRx = /http.*analyses(\/\d+)*/g;
  const [baseUrl] = uiFileListUrl.match(uiFileBaseUrlRx);
  let uiFilesUrls = {};
  for (const uiFileKey in uiFilesPaths) {
    uiFilesUrls[uiFileKey] = `${baseUrl}/${uiFilesPaths[uiFileKey]}`;
  }
  return uiFilesUrls;
};

/**
 *
 * @param {Object} queryObject
 * @param {Array<string>} queryObject.queryKey
 * @returns {Promise<Array>} array of
 */
const getFileList = async ({ queryKey }) => {
  const analysisId = queryKey[1];
  const analyses = await AppAPI.Analysis.files({
    'analysis_id': analysisId,
  });

  const analysisUiFiles = Object.values(analyses).reduce((analysisUiFilesMap, analysis) => {
    const uiFiles = analysis.files.filter(
      file => ['ui_file_list.json', 'ui_file_list.txt', 'generate_trio_data/ui'].some(path => file['file_uri'].endsWith(path))
    );
    uiFiles.forEach(file => {
      analysisUiFilesMap[file['bp_analysis_id']] = file['file_uri'];
    });
    return analysisUiFilesMap;
  }, {});

  const chartFiles = {};
  const analysisUiFilesPromises = Object.entries(analysisUiFiles).map(
    async ([bpAnalysisId, uiFileListUrl]) => {
      let uiFilesUrls = {};
      if (uiFileListUrl.endsWith('ui_file_list.json')) {
        uiFilesUrls = await readFile(uiFileListUrl);
      } else if (uiFileListUrl.endsWith('ui_file_list.txt')) {
        uiFilesUrls = await readFile(uiFileListUrl, 'txt');
      } else if (uiFileListUrl.endsWith('generate_trio_data/ui')) {
        uiFilesUrls['father_baf_logr'] = `${uiFileListUrl}/father.baf_logr.tsv.gz`;
        uiFilesUrls['father_haploblocks'] = `${uiFileListUrl}/father.haploblocks.json`;
        uiFilesUrls['mother_baf_logr'] = `${uiFileListUrl}/mother.baf_logr.tsv.gz`;
        uiFilesUrls['mother_haploblocks'] = `${uiFileListUrl}/mother.haploblocks.json`;
        uiFilesUrls['reference_baf_logr'] = `${uiFileListUrl}/reference.baf_logr.tsv.gz`;
        uiFilesUrls['reference_haploblocks'] = `${uiFileListUrl}/reference.haploblocks.json`;
      }
      chartFiles[bpAnalysisId] = uiFilesUrls;
      return chartFiles;
    }
  );
  await Promise.all(analysisUiFilesPromises);
  return chartFiles;
};

export default function useChartFiles(analysisId, options) {
  const query = useQuery({
    enabled: Boolean(analysisId),
    queryKey: ['/analysis/files', analysisId],
    queryFn: getFileList,
    refetchInterval: false,
    staleTime: 1000 * 60 * 5,
    ...options,
  });

  return query;
}
