// Importing react libs
import React, { Fragment, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

// Importing antd libs
import { Layout, Skeleton, message } from 'antd';
import 'antd/es/layout/style/css';
import 'antd/es/message/style/css';
import 'antd/es/skeleton/style/css';

// Importing external libs
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing Helix modules
import { redirect } from 'helix-modules/awsregions';

// Importing app modules
import AppAPI from 'modules/api';
import Session, { EVENT_SESSION_STARTED, EVENT_SESSION_STOPPED } from 'modules/session';

// Importing app containers
import { ForgotPassword, Login, Terms } from 'containers';

// Importing app components
import { Footer, MenuHeader } from 'components/layout';
import { Profile } from 'components/user';

// Importing app routes
import RouteConfig from './routes';

// Importing app style
import './app.scss';

// Defining constants
const { Content } = Layout;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  }
});

// Overriding language from API call
const loadDefaultLanguage = async () => {
  const res = await AppAPI.Language.get('en');
  if (!res.error) {
    window.app = { ...window.app, language: res };
  }
};
loadDefaultLanguage();

// Exporting component
export default function App() {
  const Language = useLanguage();
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isLoadingContext, setIsLoadingContext] = useState(true);
  const { term_signedon: termSignedOn, id } = window.app?.user ?? {};
  const isAuthenticated = id > 0;
  const hasSignedTerms = Boolean(termSignedOn);

  const _onSignedIn = async (credentials) => {
    // Getting user data
    setIsAuthenticating(true);
    const res = await AppAPI.User.byUsername(credentials.username);
    if (res.error) {
      await AppAPI.User.logout();
      return message.error(Language.get('login', res.msg));
    }
    window.app = { ...window.app, user: res };
    dispatchEvent(new CustomEvent(EVENT_SESSION_STARTED));

    const shouldRedirectToProfile = AppAPI.User.isMFARequired && !AppAPI.User.isMFASetup;
    navigate(shouldRedirectToProfile ? '/user/profile' : window.location.pathname);
    setIsAuthenticating(false);
  };

  const _onSignedOut = async () => {
    setIsAuthenticating(true);
    await AppAPI.User.logout();
    dispatchEvent(new CustomEvent(EVENT_SESSION_STOPPED));
    setIsAuthenticating(false);
  };

  useEffect(() => {
    const loadContext = async () => {
      setIsLoadingContext(true);
      const res = await AppAPI.Context.get();
      if (!res.error) {
        window.app = { ...window.app, ...res };
        redirect?.();
      }
      setIsLoadingContext(false);
    };
    loadContext();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const load = async () => {
      setIsAuthenticating(true);
      const user = await AppAPI.User.fromSession(signal);
      if (user) {
        dispatchEvent(new CustomEvent(EVENT_SESSION_STARTED));
      }
      setIsAuthenticating(false);
    };
    load();
    return () => controller.abort();
  }, []);

  if (isAuthenticated && !hasSignedTerms) {
    return <Terms isFullPage />;
  }

  if (isAuthenticated && AppAPI.User.isMFARequired && !AppAPI.User.isMFASetup) {
    return (
      <div>
        <MenuHeader isAuthenticated={isAuthenticated} onSignedOut={_onSignedOut} />
        <Routes>
          <Route exact key='user-profile' path='*' element={<Profile />} />
        </Routes>
      </div>
    );
  }

  return (
    <Layout>
      <Session />
      {(isAuthenticating || isLoadingContext) ? <Skeleton className='is-authenticating' active /> : (
        isAuthenticated && !hasSignedTerms ? <Terms isFullPage /> : (
          <Fragment>
            <MenuHeader isAuthenticated={isAuthenticated} onSignedOut={_onSignedOut} />
            <Content className='site-layout'>
              {isAuthenticated ? (
                <div className="site-layout-background">
                  <QueryClientProvider client={queryClient}>
                    <Routes>
                      {RouteConfig.map((route, index) => (
                        <Route path={route.path} exact element={<route.element />} key={index} />
                      ))}
                    </Routes>
                  </QueryClientProvider>
                </div>
              ) : (
                <Routes>
                  <Route path='/forgot-password' exact element={<ForgotPassword />}></Route>
                  <Route path='/terms' exact element={<Terms hideActions />}></Route>
                  <Route path='*' element={<Login onSignedIn={_onSignedIn} />}></Route>
                </Routes>
              )}
            </Content>
            <Footer />
          </Fragment>
        )
      )}
    </Layout>
  );
}
