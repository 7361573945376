// import Abstract from './abstract';
// class Language extends Abstract {
//   /**
//    * Constructor
//   **/
//   constructor() {
//     super();
//     this.resource = 'case';
//     this._updateEndpoint();
//   }
// }
// TODO: replace with proper API call
class Language {
  async get(id) {
    return {
      id,
      dictionary: {
        accessioning: {
          ANALYSIS_ID: 'Analysis ID',
          ACCESSIONING: 'Accessioning',
          AT_LEAST_ONE_TRIO_IS_REQUIRED: 'Define trio before adding samples',
          BEADCHIP_TYPE: 'BeadChip Type',
          CANNOT_MODIFY_LOCKED_CASE: 'Cannot modify a locked case',
          CASE_ARRAY_VERSION_IS_NOT_EDITABLE: 'Array version cannot be changed',
          CASE_ID_ALREADY_EXIST: 'Case ID already in use',
          CASE_V1_ARRAY_CAN_ONLY_BE_IMPORTED: 'Case with v1 array can only be created through import',
          ERROR_403: 'Action not allowed',
          ERROR_500: 'Invalid entries',
          NOT_VALID_ARRAY_TYPE: 'Invalid array type',
          NOT_VALID_ARRAY_TYPE_MISMATCH: 'Array type does not match the selected case',
          NOT_VALID_BEADCHIP_BARCODE: 'Invalid beadchip serial',
          NOT_VALID_BIOMATERIAL_TYPE: 'Invalid sample type',
          NOT_VALID_CASE_ID: 'Invalid Case ID',
          NOT_VALID_CASE_ID_DOES_NOT_EXIST: 'Case ID does not exist',
          NOT_VALID_CHARACTERS_IN_HEADER_FIELDS: 'Invalid characters in header fields',
          NOT_VALID_CHARACTERS_COUNT_IN_HEADER_FIELDS: 'Invalid no. of characters in header fields',
          NOT_VALID_CHARACTERS_IN_SAMPLE_FIELDS: 'Invalid characters in sample fields',
          NOT_VALID_CHROMOSOME_GENE_REGION_FIELD: 'Invalid chromosome in gene region',
          NOT_VALID_CHROMOSOME_POSITION_GENE_REGION_FIELD: 'Invalid chromosome position in gene region',
          NOT_VALID_DATA: 'Invalid data. Number of values provided does not match the number of columns',
          NOT_VALID_DOB: 'Invalid date of birth',
          NOT_VALID_DUPLICATED_BARCODES: 'Duplicated sample barcodes',
          NOT_VALID_DUPLICATED_TUBE_IDS: 'Duplicated Tube IDs within the case',
          NOT_VALID_DUPLICATED_BIOMATERIAL_NAMES: 'Duplicated Sample IDs within the case',
          NOT_VALID_DUPLICATED_ANALYSIS_NAMES: 'An analysis with this ID already exists',
          NOT_VALID_EMPTY_GENE_REGIONS_FIELDS: 'Missing gene region fields',
          NOT_VALID_EMPTY_HEADER_FIELDS: 'Missing header fields',
          NOT_VALID_EMPTY_SAMPLE_FIELDS: 'Sample IDs cannot be empty',
          NOT_VALID_EMPTY_TRIO_FIELDS: 'Missing trio fields',
          NOT_VALID_EMPTY_VALUE: 'Cannot be empty',
          NOT_VALID_FATHER_PEDEGREE_TRIO: 'Invalid pedigree for father in trio',
          NOT_VALID_FILE_DATE: 'Invalid file date',
          NOT_VALID_FILE_TYPE: 'Invalid file type',
          NOT_VALID_FILE_VERSION: 'Invalid file version',
          NOT_VALID_ID: 'Invalid ID format',
          NOT_VALID_MISMATCH_GENE_REGIONS_TRIO_STATUSES: 'Mismatch of gene region with trio section',
          NOT_VALID_MOTHER_PEDEGREE_TRIO: 'Invalid pedigree for mother in trio',
          NOT_VALID_MUTATION_SITE_GENE_REGIONS: 'Invalid mutation site in gene regions',
          NOT_VALID_NUMBERS_IN_SAMPLE_FIELDS: 'Invalid numbers in sample fields',
          NOT_VALID_REFERENCE_PEDEGREE_TRIO: 'Invalid pedigree for reference in trio',
          NOT_VALID_SAMPLE_STATUS_GENE_REGIONS: 'Invalid sample status in gene regions',
          NOT_VALID_SEX_TRIO: 'Invalid sex in trio',
          NOT_VALID_SUBARRAY: 'Invalid sub array',
          NOT_VALID_REFERENCE_SEX_TRIO: 'Invalid reference sex in trio',
          NOT_VALID_TYPE_GENE_REGIONS: 'Invalid type in gene regions',
          NOT_VALID_WELL_POSITION: 'Invalid well position',
          NOT_VALID_NUMBER_OF_FATHER_REFERENCE_SAMPLE: 'Invalid number of father samples',
          NOT_VALID_NUMBER_OF_MOTHER_REFERENCE_SAMPLE: 'Invalid number of mother samples',
          NOT_VALID_NUMBER_OF_REFERENCE_SAMPLES: 'Invalid number of reference samples',
          NOT_VALID_ALPHANUMERIC: 'Invalid alphanumeric character',
          NOT_VALID_MAX_LENGTH: 'Maximum length of value excedeed',
          NOT_VALID_GENE_REGIONS_AMOUNT: 'Maximum number of gene regions excedeed',
          NOT_VALID_GENE_TYPE: 'Invalid inheritance type',
          NOT_VALID_BIOMATERIAL_NOT_FOUND: 'Sample cannot be found',
          NOT_VALID_BIOMATERIAL_GENE_REGION_STATUS: 'Missing required sample status',
          NOT_VALID_GENE_REGION_DISEASE: 'Invalid gene region (disease) definition',
          NOT_VALID_TRIO_EMPTY_FIELDS: 'Missing trio fields',
          NOT_VALID_OPTION: 'Selected option for this field is not valid',
          NOT_VALID_GENE_REGION_NOT_FOUND: 'Gene region cannot be found',
          NOT_VALID_BIOMATERIAL_IS_IN_A_POOLED_PLATE: 'Sample cannot be deleted because it is in a finalised Plate',
          NOT_VALID_DATE_FORMAT: 'Invalid date format',
          NOT_VALID_OBJECTS_DO_NOT_BELONG_TO_CASE: 'Invalid because the object does not belong to the specified case',
          NOT_VALID_ANALYSIS_REFERENCE_COMBINATION_ALREADY_EXIST_IN_CASE: 'Analysis with the same Trio and/or Reference combination already exists for this case',
          NOT_VALID_VALUE_OUT_OF_LIMITS: 'Value is out of limits',
          NOT_VALID_GENE_REGION_START_END_POSITION: 'End position cannot be less than start position',
          NOT_VALID_ENCODE_FOR_IMPORT_FILE: 'Invalid file encoding type',
          NOT_VALID_BIOMATERIAL_GENE_REGION_STATUS_DOES_NOT_MATCH: 'The gene region status does not match with sample of the same designation',
          REFERENCE_SAMPLE: 'Reference Sample',
          REFERENCE_TYPE: 'Reference Type'
        },
        analysis: {
          ANALYSIS_NAME: 'Analysis Name',
          ANALYSIS_NAME_INFO: 'Unique name of the analysis. By default the reference used is added here for easier identification of the analysis.',
          ANALYSES: 'Analyses',
          ANALYSIS_ID: 'Analysis ID',
          ANALYSIS_STALE_WARNING: 'Analysis results are outdated as case settings have changed. Please reanalyze.',
          ANALYSIS_STATUS_COMPLETED: 'Analysis completed.',
          ANALYSIS_STATUS_COMPLETED_TRIO: 'Analysis completed for trio samples.',
          ANALYSIS_STATUS_FAILED_TRIO: 'Analysis failed trio analysis.',
          ANALYSIS_STATUS_FAILED_EMBRYOS: 'Analysis failed sample(s) analyses.',
          ANALYSIS_STATUS_PROCESSING_TRIO: 'Trio samples are being processed for this analysis.',
          ANALYSIS_STATUS_PROCESSING_EMBRYOS: 'Samples are being processed for this analysis.',
          ANALYSIS_STATUS_WAITING: 'Analysis waiting.',
          CONFIRM_REANALYZE: 'Are you sure you want to reanalyze this analysis?',
          BY: 'by',
          NOTES: 'Notes',
          NOT_VALID_NUMBER_OF_REFERENCE_SAMPLES: 'Invalid number of reference samples',
          NOT_VALID_NUMBER_OF_FATHER_REFERENCE_SAMPLE: 'Invalid number of father samples',
          NOT_VALID_NUMBER_OF_MOTHER_REFERENCE_SAMPLE: 'Invalid number of mother samples',
          REANALYZE: 'Reanalyze',
          REANALYSIS_TRIGGERED: 'Reanalysis triggered successfully',
          REFERENCE_ID: 'Reference ID',
          WARNINGS: 'Warnings'
        },
        beadchip: {
          BEADCHIPS_SCANNED: 'Beadchips Scanned',
          BEADCHIPS_WAITING: 'Beadchips Waiting',
          PLATE_ID: 'Plate ID',
          PREP_DATE: 'Prep-Date',
          WAITING: 'WAITING'
        },
        biomaterial: {
          ANEUPLOID: 'Aneuploid',
          BARCODE: 'Sample Barcode',
          BARCODE_INFO: 'Lab-internal Sample Barcode',
          BEADCHIP_BARCODE: 'BeadChip Serial',
          BEADCHIP_BARCODE_INFO: 'BeadChip Serial number automatically filled after plate design',
          BIO_TYPE: 'Sample type',
          BIOMATERIALS_SUMMARY: 'Sample summary',
          BIOMATERIALS: 'Samples',
          BLANK: 'Blank',
          BLASTOMERE: 'Blastomere',
          CANNOT_MODIFY_LOCKED_CASE: 'Cannot modify a locked case',
          CASE_ID: 'Case ID',
          CASE_HAS_AT_LEAST_ONE_ANALYSIS_SET: 'Sample cannot be deleted because the Case has at least one analysis set',
          CYCLE_ID: 'Cycle ID',
          CYCLE_ID_INFO: 'Lab-internal cycle ID of the sample',
          CONCENTRATION: 'Concentration',
          CONCENTRATION_INFO: 'Sample concentration',
          DESIGNATION: 'Designation',
          DOB: 'DOB',
          DOB_INFO: 'Optional date of birth',
          EXCLUDE: 'Exclude',
          EUPLOID: 'Euploid',
          ERROR_403: 'Action not allowed',
          FATHER: 'Father',
          FEMALE: 'Female',
          FIRSTNAME: 'First name',
          FIRSTNAME_INFO: 'Optional first name',
          GDNA: 'Genomic DNA',
          GDNA_BLOOD: 'gDNA blood',
          GDNA_SALIVA: 'gDNA saliva',
          LASTNAME: 'Last name',
          LASTNAME_INFO: 'Optional last name',
          MALE: 'Male',
          MATERN_AUNT: 'Maternal Aunt',
          MATERN_GRAND_FATHER: 'Maternal Grandfather',
          MATERN_GRAND_MOTHER: 'Maternal Grandmother',
          MATERN_UNCLE: 'Maternal Uncle',
          MOTHER: 'Mother',
          NAME: 'Name',
          NAME_INFO: 'Lab-specific name of sample',
          NO_RESULT: 'No Result',
          NOT_VALID_BIOMATERIAL_IS_IN_A_POOLED_PLATE: 'Sample cannot be deleted as it is part of a plate',
          OTHER: 'Other',
          PATERN_AUNT: 'Paternal Aunt',
          PATERN_GRAND_FATHER: 'Paternal Grandfather',
          PATERN_GRAND_MOTHER: 'Paternal Grandmother',
          PATERN_UNCLE: 'Paternal Uncle',
          PB_1: 'Polar Body 1',
          PB_2: 'Polar Body 2',
          PEDEGREE: 'Pedigree',
          PEDEGREE_INFO: 'Relationship to sample',
          REFERENCE: 'Reference',
          REFERENCE_SAMPLES: 'Reference samples',
          REQUISITION: 'Requisition',
          REQUISITION_INFO: 'Lab-specific requisition number',
          REGIONS: 'Regions',
          SAMPLE: 'Sample',
          SAMPLES: 'Samples',
          SAMPLE_ID: 'Sample ID',
          SAMPLE_ID_INFO: 'Unique Sample ID',
          SAMPLE_REFERENCE: 'Sample reference',
          SEGMENTAL: 'Segmental',
          SEX: 'Sex',
          SEX_INFO: 'Sample sex',
          SIBLING: 'Sibling',
          SPERM: 'Sperm',
          STATUS: 'Status',
          SUB_ARRAY_ID: 'Subarray',
          SUB_ARRAY_ID_INFO: 'Subarray automatically filled after plate design',
          TROPHECTODERM: 'Trophectoderm',
          TUBE_ID_INFO: 'Lab-specific tube ID',
          TUBE_ID: 'Tube ID',
          TYPE: 'Type',
          TYPE_INFO: 'DNA origin of sample',
          UNKNOWN: 'Unknown',
          VOLUME: 'Volume',
          VOLUME_INFO: 'Sample volume',
          WAITING: 'Waiting',
          '260_230': '260/230',
          '260_280': '260/280',
          '260_230_INFO': 'DNA concentration at 260/230',
          '260_280_INFO': 'DNA concentration at 260/280'
        },
        case: {
          ACTIONS: 'Actions',
          ANALYSIS: 'Analysis',
          ANALYSIS_ID: 'Analysis ID',
          BIOMATERIALS_IMPORT_SUMMARY: 'Sample import summary',
          BIOMATERIALS_IMPORT_SUMMARY_CONFIRM: 'Samples will be imported into the case. Proceed?',
          BIOMATERIALS_SUMMARY: 'Sample summary',
          CANCEL_SIGN_OFF: 'Cancel Sign-off',
          CANCEL_SIGN_OFF_CONFIRM: 'Are you sure you want to cancel sign-off for this case?',
          CASE_ID: 'Case ID',
          CASE_ID_INFO: 'Helper text for the CASE ID.',
          CASE_IMPORT_SUMMARY: 'Case import summary',
          CASE_IMPORT_SUMMARY_CONFIRM: 'Case, gene regions and samples will be imported. Proceed?',
          CASE_FA_IMPORT_SUMMARY_CONFIRM: 'Case, gene regions, samples and beadchips will be imported. Proceed?',
          CASE_LIST: 'CASE LIST',
          CASE_NAME: 'Case ID',
          CASES: 'Cases',
          CASE_SUMMARY: 'Case Summary',
          CHROMOSOME: 'Chromosome',
          COMMON_BREAKPOINTS: 'Common Breakpoints',
          CONCENTRATION: 'Concentration (ng/µL)',
          CONSULTANT: 'Referred By',
          CYCLE_ID: 'Cycle ID',
          DISEASE_STATUS: 'Disease Status',
          DISORDER_TYPE: 'Disorder Type',
          END: 'End',
          EVIDENCE_SUPPORT: 'Key SNP support',
          FIRST_NAME: 'First name',
          FLANKING_SIZE: 'Flanking size',
          LAST_NAME: 'Last name',
          LEFT_REGION: 'Left Flanking Region (5\')',
          LOCATION: 'Location',
          LOCK: 'Lock',
          LOG_TAB: 'Log',
          MAIN_REGION: 'Main Region',
          MATERNAL_DETAILS: 'Maternal Details',
          MATERNAL_INFORMATIVE_SNPS: 'Maternal Informative SNPs',
          MATERNAL_LASTNAME: 'Maternal Last Name',
          MATERNAL_SAMPLE_ID: 'Maternal Sample ID',
          MATERNAL_STATUS: 'Maternal Status',
          MIS_CALL: 'Mis-Call',
          NO_PLATE_FOUND_FOR_THE_SELECTED_CASE: 'No plate found for the selected case',
          NON_SUP_EVIDENCE: 'Contrary Evidence',
          NOTES: 'Notes',
          NO_ANALYSIS_FILES: 'No result files available for analysis',
          NO_SAMPLE_FILES: 'No result files available for sample',
          OVERVIEW_TAB: 'Case View',
          PATERNAL_DETAILS: 'Paternal Details',
          PATERNAL_INFORMATIVE_SNPS: 'Paternal Informative SNPs',
          PATERNAL_STATUS: 'Paternal Status',
          PLATFORM_SNPS: 'Platform SNPs',
          PREDICTED_PHASE: 'Predicted Phase',
          PREDICTED_STATUS: 'Predicted Status',
          QC: 'QC',
          QC_BEAD_ARRAYS: 'QC: Bead Arrays',
          QC_DNA: 'QC: DNA',
          REFERENCE_DETAILS: 'Reference Details',
          REFERENCE_ID: 'Reference ID',
          REFERENCE_STATUS: 'Reference Status',
          REFERENCE_TYPE: 'Reference Type',
          REFERRING_CENTRE: 'Referring Centre',
          REGION: 'Region',
          REGION_DETAILS: 'Region Details',
          REGION_STATISTICS: 'Region Statistics',
          REPORT_SAMPLES_TAB: 'Report: Samples',
          REPORT_SUMMARY_TAB: 'Report: Summary',
          RESULT: 'Result',
          RIGHT_REGION: 'Right Flanking Region (3\')',
          SAMPLE_DETAILS: 'Sample Details',
          SAMPLE_DETAIL_TAB: 'Sample Detail View',
          SAMPLE_ID: 'Sample ID',
          SAMPLE_RESULT: 'Sample Result',
          SAMPLE_TYPE: 'Type',
          SIGN_OFF: 'Sign off',
          SIZE: 'Size',
          SNP_DETAILS: 'SNP Details',
          SNPS_DATA: 'Available SNPs',
          START: 'Start',
          SUBARRAY: 'Subarray',
          SUBJECT_ID: 'Maternal Sample ID',
          SUBJECT_NAME: 'Maternal Last Name',
          SUBJECT_FIRSTNAME: 'Maternal First Name',
          SUBJECT_LASTNAME: 'Maternal Last Name',
          SUP_EVIDENCE: 'Supporting Evidence',
          TUBE_ID: 'Tube ID',
          TWO_REGION_VIEW: 'Two Region View',
          UNKNOWN: 'Unknown',
          UNLOCK: 'Unlock',
          V1_ARRAY: 'v1 array',
          VOLUME: 'Sample Volume (µL)',
          X_HET_RATE: 'X Het Rate',
          Y_CALL_RATE: 'Y Call Rate'
        },
        chart: {
          B_ALLELE_CHART: 'B-Allele Chart',
          CNV_CHART: 'CNV Chart',
          DETAILED_HAPLOBLOCK_CHART: 'Detailed Haploblock Chart',
          HAPLOBLOCK_REFERENCE: 'Haploblock Reference',
          IDEOGRAM: 'Idiogram',
          KARYOMAP: 'KaryoMap',
          LOGR_CHART: 'LogR Chart'
        },
        common: {
          ACTION: 'Action',
          ACTIONS: 'Actions',
          ADD: 'Add',
          ADDRESS: 'Address',
          ADDRESS_INFO: 'Your address to be displayed on your reports',
          ADD_NOTES: 'Add Notes',
          AGREE: 'Agree',
          ANALYSIS_NOT_AVAILABLE: 'Analysis not available.',
          ARE_YOU_SURE_CANCEL_EDITION: 'Unsaved data will be lost. Proceed?',
          AUTHENTICATION_CREDENTIALS_WERE_NOT_PROVIDED: 'Please log in and refresh page',
          BEAD: 'Bead',
          CANCEL: 'Cancel',
          CATEGORIES: 'Categories',
          NOT_VALID_EMPTY_VALUE: 'Cannot be empty',
          CANNOT_BE_LARGER_THAN: 'Cannot be larger than',
          CLONE_HOVER_INFO: 'Clone',
          CLOSE: 'Close',
          CFR: 'CFR',
          CONFIRM_DELETION: 'Confirm delete',
          COPYRIGHT: 'All content © Vitrolife 2023',
          CREATED: 'Created',
          CW: 'CW',
          DATA_SAVED_SUCCESFULLY: 'Data saved successfully',
          DATE_CREATED: 'Date Created',
          DATE_MODIFIED: 'Date Modified',
          DELETE: 'Delete',
          DELETE_HOVER_INFO: 'Delete',
          DETAIL: 'Detail',
          DISAGREE: 'Disagree',
          DOWNLOAD: 'Download',
          DUPLICATE_SIGNOFF: 'Active signoff for given case already exists',
          EDIT: 'Edit',
          EDIT_HOVER_INFO: 'Edit',
          EDITING: 'Editing',
          ENABLED: 'Enabled',
          ERROR_401: 'Not authorised',
          ERROR_403: 'Action not allowed',
          ERROR_500: 'Internal server error',
          EXPORT_TO_TSV: 'Export to TSV',
          FILES: 'Files',
          GLOBAL_SEARCH: 'Global Search',
          ID: 'ID',
          IMPORT: 'Import',
          LAST_UPDATE: 'Last Update',
          LAST_UPDATED: 'Last Updated',
          LOADING: 'Loading',
          LOGO: 'Logo',
          LOGO_INFO: 'Upload a png or jpeg file of max. 500x500 pixel to be displayed on your reports',
          NAME: 'Name',
          NEXT: 'Next',
          NEW: 'New',
          NO: 'No',
          NOTES: 'Notes',
          NOT_AUTHORIZED: 'User is not allowed to perform this action',
          NOT_ENABLED: 'Not enabled',
          NOT_FOUND: 'Not found',
          NOT_VALID_ALPHANUMERIC: 'Invalid alphanumeric character',
          OF: 'of',
          OK: 'Ok',
          PLANNER: 'Planner',
          PREVIOUS: 'Previous',
          QC: 'QC',
          REGION: 'Region',
          REQUIRED_FIELD: 'This field is required',
          RELEASE: 'Release',
          RESEARCH_USE_ONLY: 'For Research Use Only. Not for use in diagnostic procedures.',
          RESET_ZOOM: 'Reset Zoom',
          ROW: 'Row',
          SAMPLES: 'Samples',
          SAVE: 'Save',
          SEARCH_BY: 'Search By',
          SEARCH: 'Search',
          SHOW_MORE: 'Show More',
          SHOW_MORE_INFO: 'Show More Info',
          SORT_BY: 'Sort By',
          STATUS: 'Status',
          TERMS: 'Terms',
          TERMS_AND_CONDITIONS: 'Terms & Conditions',
          TERMS_NOT_SIGNED: 'User has not agreed to terms',
          TYPE: 'Type',
          UPDATE: 'Update',
          VIEW: 'View',
          VIEW_HOVER_INFO: 'View details',
          VITROLIFE_TRINITY: 'Vitrolife kMap',
          YES: 'Yes'
        },
        dashboard: {
          ALERTS: 'Alerts',
          APPLY: 'Apply',
          NOT_VALID_EMPTY_VALUE: 'Field cannot be empty',
          DATE: 'Date',
          EXPORT_DATA: 'Export',
          EUPLOID: 'No abnormalities detected',
          MEIOTIC_ANEUPLOID: 'Meiotic aneuploid',
          MEIOTIC_SEGMENTAL: 'Meiotic segmental',
          MITOTIC_ANEUPLOID: 'Mitotic aneuploid',
          MITOTIC_SEGMENTAL: 'Mitotic segmental',
          NO_RESULT: 'No result',
          NO_RESULT_NO_RESULT: 'No result',
          QC_FAIL: 'QC Fail',
          QC_PASS: 'QC Pass',
          RECENT_BEADCHIP_ARRAYS: 'Recent Beadchips',
          RECENT_CASES: 'Recent Cases',
          REFERRING_CENTRE: 'Referring centre',
          SETTINGS: 'Settings',
          STATISTICS: 'Statistics',
          TIME_FRAME_END: 'End week',
          TIME_FRAME_START: 'Start week',
          UNKNOWN_EUPLOID: 'Euploid',
          UNKNOWN_NO_RESULT: 'No result'
        },
        file: {
          DATA_EXPORT_FILE: 'Data export file',
          FILE_DOWNLOAD_FAILED: 'Error while downloading file',
          FILE_SIZE: 'File Size',
          FILES_FOR_CASE_ID: 'Files for case ID',
          NO_FILES_PRESENT: 'The files are not present',
          RAW_INPUT_FILES: 'Raw input files',
          RESULT_FILES: 'Result files',
          UI_FILES: 'UI files',
          ZIPPING: 'Compressing'
        },
        footer: {},
        gene: {
          AUTOSOMAL_DOMINANT: 'Autosomal dominant',
          AUTOSOMAL_RECESSIVE: 'Autosomal recessive',
          AFFECTED: 'Affected',
          CARRIER: 'Carrier',
          CHROM: 'Chromosome',
          CHROM_INFO: 'Chromosome of region',
          CYTOBAND: 'Cyto-band of region',
          DISEASES: 'Gene selection',
          DISEASES_INFO: 'Select from list of frequently tested genes.',
          END: 'End',
          END_INFO: 'Basepair end location of region',
          FATHER: 'Father',
          FAILED: 'Failed',
          GENE: 'Gene',
          GENE_REGION: 'Gene / Region',
          GENE_REGION_LIMIT: 'Cannot add more Genes / Regions. Limit for a case is 10.',
          GENE_REGION_SELECTOR_SUFFIX: 'Custom',
          GENES_SUMMARY: 'Genes summary',
          HEMIZYGOUS_AFFECTED: 'Hemizygous affected',
          HEMIZYGOUS_AFFECTED_MATERNAL: 'Hemizygous affected maternal',
          HEMIZYGOUS_NORMAL: 'Hemizygous normal',
          HETEROZYGOUS: 'Heterozygous',
          HETEROZYGOUS_AFFECTED: 'Heterozygous affected',
          HETEROZYGOUS_AFFECTED_MATERNAL: 'Heterozygous affected maternal',
          HETEROZYGOUS_AFFECTED_PATERNAL: 'Heterozygous affected paternal',
          HETEROZYGOUS_CARRIER: 'Heterozygous carrier',
          HETEROZYGOUS_CARRIER_MATERNAL: 'Heterozygous carrier maternal',
          HETEROZYGOUS_CARRIER_PATERNAL: 'Heterozygous carrier paternal',
          HOMOZYGOUS_AFFECTED: 'Homozygous affected',
          HOMOZYGOUS_NORMAL: 'Homozygous normal',
          INVALID_STATUS: 'Invalid Status',
          LINKS: 'Links',
          MOTHER: 'Mother',
          MUTATION_SITE: 'Mutation site',
          MUTATION_SITE_INFO: 'Basepair location of case-specific mutation site',
          NAME: 'Region name',
          NAME_INFO: 'Define name for this region.',
          NA: 'NA',
          NO_REGION_SPECIFIED: 'No region specified',
          NO_RESULT: 'No Result',
          NOT_VALID_EMPTY_VALUE: 'Cannot be empty',
          REFERENCE: 'Reference',
          REFERENCES: 'Reference(s)',
          START_INFO: 'Basepair start location of region',
          TYPE_INFO: 'Mode of inheritance of the gene / disease',
          START: 'Start',
          TYPE: 'Inheritance type',
          UNAFFECTED: 'Unaffected',
          UNKNOWN: 'Unknown',
          WAITING: 'Waiting',
          X_LINKED_DOMINANT: 'X linked dominant',
          X_LINKED_RECESSIVE: 'X linked recessive'
        },
        organization: {
          ANALYSES_SETTINGS: 'Analysis Settings',
          BEAD_ARRAY_FILE: 'Manifest file',
          BEAD_ARRAY_VERSION: 'BeadArray Type',
          BEAD_ARRAY_VERSION_INFO: 'Default BeadArray Type',
          CN_CALLING_OPTIONS: 'CN calling options',
          CN_CALLING_OPTIONS_INFO: 'CN calling Options Info',
          DEBUG: 'Debug',
          DISCLAIMER: 'Disclaimer',
          DISCLAIMER_INFO: 'Additional Text to be printed into the footer of your reports',
          ENABLE_BFP_FOR_ALL: 'Enable BFP for all users',
          ENABLE_BFP_FOR_ALL_INFO: 'Enable Brute Force Proection for all Group users. Once enabled, consecutive failed login attempts will trigger a temporary 5-minute lock-out for the user and an e-mail notification shall be sent to Level 2 users.',
          ENABLE_MFA_FOR_ALL: 'Enable MFA for all users',
          ENABLE_MFA_FOR_ALL_INFO: 'Enable Multi-Factor Authentication for all Group users. Once enabled, each user will be required to setup MFA on their Profile page, before eMap access is granted',
          FLANKING_REGION: 'Flanking Region Info',
          FLANKING_REGION_INFO: 'Megabase flanking size to be added to regions analysed',
          HUMANKARYOMAP_12_V1: 'HumanKaryomap-12',
          I_SCAN: 'iScan',
          INCLUDE_CNV_CHART: 'Include CNV chart',
          INCLUDED_CHARTS: 'Charts included',
          INCLUDED_CHARTS_INFO: 'The selected charts will be printed into the report.',
          INFO: 'Info',
          INTENSITY_REFERENCE: 'Intensity Reference',
          INTENSITY_REFERENCE_INFO: 'Intensity Reference Info',
          KARYOMAP_V2: 'KaryoMap v2',
          LOG_LEVEL: 'Log level',
          LOG_LEVEL_INFO: 'Select detail level for analysis logs',
          MISCELLANEOUS: 'Miscellaneous',
          NA: 'NA',
          NEXT_SEQ: 'NextSeq 550',
          ORGANIZATION: 'Organisation',
          ORGANIZATIONS: 'Organisations',
          PDF_GUIDE_KEY_1: 'PDF Guide Key 1',
          PDF_GUIDE_KEY_1_INFO: '1st field for laboratory planner',
          PDF_GUIDE_KEY_2: 'PDF Guide Key 2',
          PDF_GUIDE_KEY_2_INFO: '2nd field for laboratory planner',
          PDF_GUIDE_KEY_3: 'PDF Guide Key 3',
          PDF_GUIDE_KEY_3_INFO: '3rd field for laboratory planner',
          REPORT_SETTINGS: 'Report Settings',
          RESTORE_DEFAULTS: 'Restore Defaults',
          REMOVE_SELECTED_ORGANIZATION: 'Remove selected organisation',
          REPORT_INTRODUCTION: 'Report Introduction',
          REPORT_INTRODUCTION_INFO: 'Additional text to be printed on the first page of your reports',
          SCANNING_HARDWARE: 'Scanning hardware',
          SCANNING_HARDWARE_INFO: 'Default scanning hardware',
          SESSION_TIMEOUT: 'Session timeout',
          SESSION_TIMEOUT_INFO: 'Seconds of inactivity prior to kMap session expiry. The user will be automatically logged out after this time.',
          SESSION_TIMEOUT_MODAL_TITLE: 'The session has expired. Please log in.',
          UNAUTHORIZED: 'Unauthorised',
          VERSION_1: 'Version-1',
          WARN: 'Warn'
        },
        header: {
          ACCOUNT: 'Account',
          CASE_LIST: 'Case List',
          BEAD_ARRAYS: 'Bead Arrays',
          DATA: 'Data',
          HOME: 'Home',
          LOGOUT: 'Logout',
          LOGS: 'Log File',
          MANAGE_ORGANIZATIONS: 'Manage Organisation',
          MANAGE_USERS: 'Manage Users',
          PLANNING: 'Planning',
          PROFILE: 'Profile',
          REPORT_BUILDER: 'Report Builder',
          SETTINGS: 'Settings',
          SUPPORT: 'Support'
        },
        log: {
          CREATED: 'Created',
          LOGS: 'Log File',
          LOG_DATA: 'Log Data',
          FILTERED_LOG_DATA: 'Filtered Log Data',
          USER_ID: 'User ID'
        },
        login: {
          ATTEMPT: 'Attempt',
          ATTEMPTS: 'Attempts',
          AUTH_APP: 'Authenticator App',
          NOT_VALID_EMPTY_VALUE: 'Cannot be Empty',
          CONFIRM_PASSWORD: 'Confirm Password',
          CURRENT: 'Current',
          DOES_NOT_MATCH: 'Password does not match',
          EMAIL: 'Email',
          EMAIL_NOT_VALID: 'Email not valid',
          ENTER_MFA_CODE: 'Enter MFA code',
          EXPIRED_CODE: 'Code has expired',
          FAILED: 'Failed',
          FORGOT_PASSWORD: 'Forgot password?',
          FORGOT_PASSWORD_TEXT: 'We will send you an email for you to reset your password:',
          INVALID_CODE: 'Invalid code',
          INVALID_GRANT: 'Invalid username or password',
          LOGIN: 'Login',
          MAX_LOGIN_ATTEMPTS_EXCEEDED: 'User is locked out due to too many failed login attempts',
          MFA_CODE_SENT_SUCCESS: 'MFA code has been sent to your email',
          NOT_ACTIVE_USER: 'User is not active',
          NOT_VALID_EMAIL: 'Email not valid',
          NOT_VALID_CREDENTIAL: 'Username or password not valid',
          NOT_VALID_USER: 'Invalid user',
          PASSWORD: 'Password',
          PASSWORD_IS_REQUIRED: 'Password is required',
          REENTER_PASSWORD: 'Re-Enter Password',
          REMAINING: 'Remaining',
          SEND: 'Send',
          SEND_FORGOT_PASSWORD: 'Please check your email inbox',
          SEND_MFA_CODE: 'Send MFA code',
          TRUST_DEVICE: 'Trust this device',
          USER_LOCKOUT_COUNTDOWN: 'Authentication is blocked for $countdown due to $failedAttempts failed attempts.',
          USERNAME: 'Username',
          USERNAME_IS_REQUIRED: 'Username is required',
          VERIFY_MFA_CODE: 'Verify MFA code',
          'EU-CENTRAL-1': 'Europe',
          'AP-SOUTHEAST-1': 'APAC 1',
          'AP-NORTHEAST-1': 'APAC 2',
          'AP-SOUTHEAST-2': 'APAC 3',
          'US-WEST-2': 'NA 1',
          'LOCAL': 'Local'
        },
        metrics: {
          FIXED_SCALE: 'Fixed Scale',
          AUTO_SCALE: 'Auto Scale',
          SCALE_TYPE: 'Scale Type',
        },
        planner: {
          AVAILABLE_SAMPLES: 'Available samples',
          PLATE_BUILDER: 'Plate builder',
          PLATE_DEFINITION: 'Plate definition',
          SUMMARY: 'Summary'
        },
        plate: {
          AVAILABLE_PLATES: 'Available plates',
          BARCODE: 'Sample Barcode',
          BEADCHIP_BARCODE: 'BeadChip Serial',
          BEADCHIP_DEFINITION: 'Beadchip definition',
          BEADCHIPS_DEFINED: 'Beadchips defined',
          BIOMATERIAL_ALREADY_IN_USE: 'Selected samples are already used on another plate',
          CASE_EXTERNAL_ID: 'Case ID',
          COLUMN: 'Column',
          CONFIRM_CANCEL: 'This action will close the plate edition, unsaved changes will be lost. Proceed?',
          CONFIRM_CLEAR_PLATE: 'This action will clear all unsaved wells from the plate. Proceed?',
          CONFIRM_DELETE: 'This action will permanently delete the plate and cannot be undone. Proceed?',
          CONFIRM_FINALIZE: 'This action will lock the plate and set it to be pooled. Proceed?',
          CLEAR: 'Clear',
          CREATE_NEW: 'Create new',
          CREATED_BY: 'Created by',
          CYCLE_ID: 'Cycle ID',
          DELETE_PLATE: 'Delete plate',
          DUPLICATE_BARCODE_NOT_ALLOWED: 'Duplicate BeadChip serial numbers are not allowed',
          ERROR_403: 'Action not allowed',
          FAST_TRACK: 'Fast Track',
          FINALIZE: 'Finalise',
          GENERATE_PLANNER: 'Generate planner',
          CANNOT_BE_DELETED_HAS_ANALYSIS: 'There are results linked to this plate already. Please delete the anlaysis first.',
          HYBRIDIZED: 'Hybridised',
          WELL_POSITION: 'Well Position',
          KEY: 'Key',
          KMAP_POOLING_GUIDE: 'kMap Laboratory Planner',
          LABORATORY_PLANNER: 'Laboratory Planner',
          LOT_BOX_1: 'Quad WG-Pre Lot',
          LOT_BOX_2: 'WG-Post 1 LV1 Lot',
          LOT_BOX_3: 'Single Post 3 LV Lot',
          LOT_BOX_4: 'Post 2 LMV Lot',
          LOT_BOX_5: 'Post 4 LV Lot',
          NAME: 'Sample ID',
          NOT_VALID_BEADCHIP_BARCODE_LENGTH: 'Barcode must be exactly 12 digits',
          NOT_VALID_DIFFERENT_BARCODE_IN_SAME_COLUMN: 'Different beadchip serial for two samples in same column',
          NOT_VALID_DUPLICATED_BARCODES_WITHIN_ORGANIZATION: 'Invalid duplicated BeadChip serial number within the organisation',
          NOT_VALID_DUPLICATED_PLATE_AND_POSITION: 'A sample already exists on the plate in this position',
          NOT_VALID_EMPTY_PLATE: 'At least one sample need to be placed in the plate',
          NOT_VALID_PREPARED_ON_DATE: 'Prepared on date cannot be past date',
          NOT_VALID_WELL_POSITION_SUBARRAY_COMBINATION: 'Invalid combination of well postion and subarray',
          OPERATOR: 'Operator',
          PAGE: 'Page',
          PLATE_ALREADY_POOLED: 'This plate was already pooled and does not allow any further changes',
          PLATE_ID_ALREADY_EXIST: 'Plate with this ID already exists',
          PLATE_BUILDER: 'Plate builder',
          PLATE_GRAPHIC: 'Plate graphic',
          PLATE_ID: 'Plate ID',
          POOLED: 'Pooled',
          PREPARATION_DATE: 'Preparation date',
          REQUISITION: 'Requisition',
          ROW_A_NO_BLOOD_TYPE: 'A sample that is not blood type was set in row A',
          SAMPLE_NAME: 'Sample name',
          SAMPLE_PREPARATION: 'Sample preparation',
          START_PLATE_BUILDER: 'Start plate builder',
          STARTING_COLUMN: 'Starting column',
          TABLE_BY_WELL: 'Table by well',
          TUBE_ID: 'Tube ID',
          VIEW_LAB_PLANNER: 'View lab planner',
          WELL_IN_USE: 'Well already in use',
          WELL_NOT_AVAILABLE: 'Well not available'
        },
        reportBuilder: {
          CASE_SEARCH_TITLE: 'Samples by case',
          CASE_SEARCH_PLACEHOLDER: 'Type to search',
          CREATE_REPORT: 'Create Report',
          DND_DROPZONE_PLACEHOLDER: 'Please drag and drop samples',
          DROP_HERE: 'Drop here',
          REPORT_BUILDER: 'Report Builder',
          SAMPLES_LIST_TITLE: 'Samples',
          SUBJECT_SEARCH_PLACEHOLDER: 'Type to find Maternal Sample ID',
          SUBJECT_SEARCH_TITLE: 'Samples by Maternal Sample ID'
        },
        sample: {
          SAMPLE_ID: 'Sample ID',
          INTERNAL_ID: 'Sample Internal ID',
          CALL_RATE: 'Call Rate',
          AA: 'AA',
          AB: 'AB',
          BB: 'BB',
          ADO: 'ADO',
          MIS_CALL: 'Mis-Call',
          X_HETEROZYGOUS_RATE: 'X-Het Rate',
          Y_CALL_RATE: 'Y Call Rate',
          ANEUPLOIDY_STATUS: 'Aneuploidy Status',
          PREDICTED_STATUS: 'Known/Predicted Status',
          OVERALL_RESULT: 'Overall Result'
        },
        signoff: {
          MODAL_TITLE: 'Sign-off Detail',
          HISTORY: 'Sign-off history'
        },
        snps: {
          KEY: 'Key',
          MATERNAL: 'Maternal',
          NON_KEY: 'Non-Key',
          PATERNAL: 'Paternal'
        },
        user: {
          ACCESS_KEYS: 'Access key',
          ACCESS_KEYS_INFO: 'Your personal access codes to upload data to your kMap account.',
          ACCESS_KEYS_MODAL_TITLE: 'Access key details',
          AT_LEAST_ONE_LOWERCASE: 'At least one lower case character is required',
          AT_LEAST_ONE_NUMBER: 'At least one numeric character is required',
          AT_LEAST_ONE_SYMBOL: 'At least one non-alphanumeric character is required',
          AT_LEAST_ONE_UPPERCASE: 'At least one upper case character is required',
          CHART_SETTINGS: 'Chart Settings',
          CONFIRM_PASSWORD_REQUIRED: 'Confirm password',
          EMAIL_NOTIFICATION: 'Email notification',
          ENTER_CODE: 'Enter code',
          ENTRY_CHARTS: 'Preferred chart',
          ERROR_403: 'Action not allowed',
          EXPANDED_CHARTS: 'Expanded charts',
          EXPIRED_CODE: 'Code has expired',
          FIRST_NAME: 'First name',
          HOST_ADMIN_HAS_ALREADY_CREATED_ONE_USER: 'A user has already been created. Adding more or updating existing users is not allowed.',
          INVALID_CODE: 'Invalid MFA code.',
          IS_DEVELOPER: 'Technical support',
          LANGUAGE: 'Language',
          LANGUAGE_EN: 'en',
          LAST_LOGIN: 'Last login',
          LAST_NAME: 'Last name',
          LEVEL_1: 'Level 1',
          LEVEL_2: 'Level 2',
          MFA_AUTH_APP: 'MFA Authenticator App',
          MFA_CODE_SENT_SUCCESS: 'MFA code has been sent to your email',
          MFA_REMOVED_SUCCESS: 'MFA has been removed successfully',
          MFA_CODE_VERIFIED_SUCCESS: 'Code verified successfully',
          MFA_EMAIL: 'MFA E-mail',
          MIN_LENGTH_REQUIRED_8: 'Password must be at least 8 characters long',
          NOT_AUTHORIZED: 'User is not authorized to make this request',
          NOT_VALID_AT_LEAST_ONE_LETTER: 'Should have at least one letter',
          NOT_VALID_AT_LEAST_ONE_NON_ALPHANUMERIC: 'Should have at least one non-alphanumeric character',
          NOT_VALID_AT_LEAST_ONE_NUMBER: 'Should have at least one number',
          NOT_VALID_MAX_LEN: 'Maximum length is',
          NOT_VALID_MIN_LEN: 'Minimum length is',
          NOT_VALID_USERNAME_CODE_001: 'User could not be created. Please contact support (Error code 001).',
          NEW_USER: 'New User',
          PASSWORD: 'Password',
          PASSWORDS_DONT_MATCH: 'Passwords don\'t match',
          PROFILE: 'Profile',
          REMOVE_SELECTED_USER: 'Remove selected user',
          RESET_MFA: 'Reset MFA',
          RESET_MFA_CONFIRM: 'This action shall remove the contact preferences and verified methods for this user profile. The user will be required to setup MFA upon next login, if the Group settings for MFA are enabled. Would you like to proceed?',
          RESET_MFA_TITLE: 'Reset MFA settings for this user',
          RESET_MFA_SUCCESS: 'MFA has been reset successfully',
          ROLE: 'Role',
          SCAN_QR_CODE: 'Scan the QR code below with your Authenticator App',
          TECHNICAL_SUPPORT_CANNOT_BE_LEVEL_2: 'Technical support user cannot be a level 2 user',
          TERMS_NOT_SIGNED: 'User has not agreed to terms',
          TOKEN_INVALID: 'Invalid token',
          TOKEN_VALIDATION_FAILED: 'Invalid token. Please request another reset password link.',
          USER: 'User',
          USERS: 'Users',
          VERIFY: 'Verify',
        },
        report: {
          '260_230': '260/230',
          '260_280': '260/280',
          AA: 'AA',
          AB: 'AB',
          ADDRESS: 'Address',
          ADO: 'ADO',
          ALGORITHM_VERSION: 'Analysis vers.',
          ANALYSIS: 'Analysis',
          ANALYSIS_REPORT: 'Analysis Report',
          ANALYSIS_ID: 'Analysis ID',
          ANALYSIS_SETTINGS: 'Analysis Settings',
          ANEUPLOIDY_STATUS: 'Aneuploidy Status',
          ARRAY_TYPE: 'Array Type',
          AVAILABLE_SNPS: 'Available SNPs',
          BAND: 'Band',
          BARCODE: 'Sample Barcode',
          BEADCHIP_BARCODE: 'BeadChip Serial',
          BB: 'BB',
          CALL_RATE: 'Call Rate',
          CASE_ID: 'Case ID',
          CASE_SUMMARY: 'Case summary',
          CHROMOSOME: 'Chromosome',
          CLICK_GENERATE_REPORT: 'Please click "Generate Report" once button is enabled',
          CONCENTRATION: 'Concentration (ng/µL)',
          CONSULTANT: 'Referred By',
          CONTRARY_EVIDENCE: 'Contrary Evidence',
          CYCLE_ID: 'Cycle ID',
          DELETE_LOGO: 'Delete logo',
          DESCRIPTION: 'Description',
          DESIGNATION: 'Designation',
          DISCLAIMER: 'Disclaimer',
          DISEASE_STATUS: 'Disease Status',
          DISORDER_TYPE: 'Disorder Type',
          DOWNLOAD_REPORT: 'Download report',
          END: 'End',
          FIRST_NAME: 'First Name',
          FLANKING_SIZE: 'Flanking Size',
          FOR_RESEARCH_ONLY: 'Vitrolife kMap. For Research Use Only. Not for use in diagnostic procedures.',
          FULL_GENOME: 'Full Genome',
          GENDER: 'Sex',
          GENERATE_REPORT: 'Generate report',
          INCLUDE_CHARTS: 'Include charts',
          KEY: 'Key',
          KEY_SNPS_SUPPORT: 'Key SNPs support',
          LAST_NAME: 'Last Name',
          LEFT_FLANKING: 'Left Flanking (5\')',
          LEFT_REGION: 'Left Flanking Region (5\')',
          LOADING_CHART_DATA: 'Loading data for the charts. This might take a while ...',
          LOADING_REPORT: 'Loading report',
          LOCATION: 'Location',
          MAIN_FLANKING: 'Main',
          MAIN_REGION: 'Main Region',
          MATERNAL_1: 'Maternal-1',
          MATERNAL_2: 'Maternal-2',
          MATERNAL_DETAILS: 'Maternal Details',
          MATERNAL_INFORMATIVE_SNPS: 'Maternal Informative SNPs',
          MATERNAL_LASTNAME: 'Maternal Last Name',
          MATERNAL_SAMPLE_ID: 'Maternal Sample ID',
          MATERNAL_STATUS: 'Maternal Status',
          MIS_CALL: 'Mis-Call',
          NON_KEY: 'Non-Key',
          NOTES: 'Notes',
          NO_HISTORY: 'No history',
          OF: 'of',
          PAGE: 'Page',
          PAGE_MARGIN: 'Margin',
          PAGE_SIZE: 'Page size',
          PATERNAL_1: 'Paternal-1',
          PATERNAL_2: 'Paternal-2',
          PATERNAL_DETAILS: 'Paternal Details',
          PATERNAL_INFORMATIVE_SNPS: 'Paternal Informative SNPs',
          PATERNAL_STATUS: 'Paternal Status',
          PEDIGREE: 'Pedigree',
          PLATFORM_SNPS: 'Platform SNPs',
          PREDICTED_PHASE: 'Predicted Phase',
          PREDICTED_STATUS: 'Predicted Status',
          QC: 'QC',
          QC_BEAD_ARRAYS: 'QC: BeadArrays',
          QC_DNA: 'QC: DNA',
          QC_STATUS: 'QC Status',
          REFERENCE_DETAILS: 'Reference Details',
          REFERENCE_STATUS: 'Reference Status',
          REFERRING_CENTRE: 'Referring Centre',
          REGION: 'Region',
          REGION_DETAILS: 'Region Details',
          REGION_STATISTICS: 'Region Statistics',
          REPORT_INTRODUCTION: 'Introduction',
          REPORT_PREVIEW: 'Preview',
          REPORT_SETTINGS: 'Report settings',
          RESULT: 'Result',
          RIGHT_FLANKING: 'Right Flanking (3\')',
          RIGHT_REGION: 'Right Flanking Region (3\')',
          SAMPLES: 'Samples',
          SAMPLE_DETAILS: 'Sample Details',
          SAMPLE_ID: 'Sample ID',
          SAMPLE_RESULT: 'Sample Result',
          SAMPLE_TYPE: 'Type',
          SAMPLE_VOLUME: 'Sample Volume (µL)',
          SIGNED_BY: 'Signed by',
          SIGNOFF_HISTORY: 'Sign-off History',
          SIZE: 'Size',
          SOFTWARE_VERSION: 'User interface version',
          START: 'Start',
          SUBARRAY: 'Subarray',
          SUBJECT_ID: 'Subject ID',
          SUBJECT_NAME: 'Subject Name',
          SUPPORTING_EVIDENCE: 'Supporting Evidence',
          TITLE: 'kMap Case Report',
          UPLOAD: 'Upload',
          WARNINGS: 'Warnings',
          X_HET_RATE: 'X-Het Rate',
          Y_CALL_RATE: 'Y-Call Rate'
        }
      }
    };
  }
}
export default new Language();
