// Importing react libs
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Importing antd libs
import { CaretDownOutlined } from '@ant-design/icons';
import { Alert, Input, Layout, Menu } from 'antd';
import 'antd/es/alert/style/css';
import 'antd/es/input/style/css';
import 'antd/es/layout/style/css';
import 'antd/es/menu/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing modules
import AppAPI from 'modules/api';
import Permission from 'modules/permission';

// Defining constants
const { Header } = Layout;

import './header.scss';

// Exporting component
export const MenuHeader = (props) => {
  const { onSignedOut } = props;
  const Language = useLanguage();
  const navigate = useNavigate();
  const [activeKeys, setActiveKeys] = useState();

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const search = params.get('q');

  const menu = [];
  if (window.app?.user?.id) {
    const isAdmin = Permission.isHostAdmin();
    // For all
    menu.push({ // Home
      key: '/dashboard',
      label: <Link to="/dashboard">{Language.get('header', 'HOME').toUpperCase()}</Link>
    });

    // If not host admin
    if (isAdmin) {
      menu.push({ // Account
        children: [{
          key: '/user/profile',
          label: <Link to="/user/profile">{Language.get('header', 'PROFILE')}</Link>,
        }, {
          key: '/user',
          label: <Link to="/user">{Language.get('header', 'MANAGE_USERS')}</Link>,
        }, {
          key: '/dashboard',
          label: <Link to="/dashboard">{Language.get('header', 'MANAGE_ORGANIZATIONS')}</Link>,
        }, {
          key: 'logout',
          label: Language.get('header', 'LOGOUT'),
          onClick: onSignedOut,
        }],
        key: 'account',
        label: <div>{Language.get('header', 'ACCOUNT').toUpperCase()} <CaretDownOutlined /></div>,
      });
    } else {
      menu.push({ // Planning
        children: [{
          label: <Link to="/accessioning">{Language.get('accessioning', 'ACCESSIONING')}</Link>,
          key: '/accessioning',
        }, {
          key: '/planner',
          label: <Link to="/planner">{Language.get('plate', 'LABORATORY_PLANNER')}</Link>,
        }],
        key: 'planning',
        label: <div>{Language.get('header', 'PLANNING').toUpperCase()} <CaretDownOutlined /></div>,
      });
      menu.push({ // Data
        children: [{
          key: '/case-list',
          label: <Link to="/case-list">{Language.get('header', 'CASE_LIST')}</Link>,
        }, {
          key: '/beadchip',
          label: <Link to="/beadchip">{Language.get('header', 'BEAD_ARRAYS')}</Link>,
        }, {
          key: '/report/builder',
          label: <Link to="/report/builder">{Language.get('header', 'REPORT_BUILDER')}</Link>,
        }, {
          key: '/log',
          label: <Link to="/log">{Language.get('header', 'LOGS')}</Link>,
        }],
        key: 'data',
        label: <div>{Language.get('header', 'DATA').toUpperCase()} <CaretDownOutlined /></div>,
      });
      menu.push({ // Account
        key: 'account',
        label: <div>{Language.get('header', 'ACCOUNT').toUpperCase()} <CaretDownOutlined /></div>,
        children: [{
          key: '/user/profile',
          label: <Link to="/user/profile">{Language.get('header', 'PROFILE')}</Link>,
        }, ...(Permission.isLevel2() ? [{
          key: '/user',
          label: <Link to="/user">{Language.get('header', 'MANAGE_USERS')}</Link>,
        }] : []), {
          key: '/organization/setting',
          label: <Link to="/organization/setting">{Language.get('header', 'SETTINGS')}</Link>,
        }, {
          key: 'support',
          label: <a href="mailto:support.genomics@vitrolife.com">{Language.get('header', 'SUPPORT')}</a>,
        }, {
          key: 'logout',
          label: Language.get('header', 'LOGOUT'),
          onClick: onSignedOut,
        }],
      });
      menu.push({ // Search
        key: 'search',
        label: (
          <div className="search-box">
            <Input
              onKeyDown={e => {
                if (e.key.startsWith('Arrow')) e.stopPropagation();
              }}
              defaultValue={search}
              placeholder={Language.get('common', 'GLOBAL_SEARCH')}
              onPressEnter={({ target: { value } }) => value ? navigate(`/organization/search?q=${value}`) : null}
            />
          </div>
        ),
      });
    }
  }

  const { pathname } = useLocation();

  useEffect(() => {
    const keys = [];
    const testItem = (item) => {
      const isChildActive = item.children?.some(child => testItem(child));
      const isItselfActive = new RegExp(`${item.key}/?[0-9]*$`).test(pathname);
      if (isItselfActive || isChildActive) {
        keys.push(item.key);
        return true;
      } else {
        return false;
      }
    };
    menu.forEach(testItem);
    setActiveKeys(keys);
  }, [pathname]);

  const calculateHeaderHeight = () => {
    const header = document.querySelector('header.header-content');
    if (header) {
      const root = document.getElementById('root');
      root.style.setProperty('--header-height', `${header.clientHeight}px`);
    }
  };

  useLayoutEffect(() => { calculateHeaderHeight(); }, []);

  const isBeforeAugust19 = new Date() < new Date(2024, 7, 19);
  const isMFASetupIncomplete = AppAPI.User.isMFARequired && !AppAPI.User.isMFASetup;

  return (
    <Header className='header-content' style={{ flexWrap: 'wrap' }}>
      {isBeforeAugust19 && props.isAuthenticated && (
        <Alert
          style={{
            backgroundColor: '#FFEB9C',
            border: 'solid 1px #A36D05',
            marginBottom: '0.5rem',
            flexBasis: '100%'
          }}
          message={<span>
            The kMap software version has been updated. Click here for the&nbsp;
            <a
              href="https://www.vitrolife.com/globalassets/_site-structure/09-product-sub-pages/09-04-genomics/kmap-software-release-notes_v0.1.2_august-2024.pdf"
              target='_blank'
              rel="noreferrer"
            >
              Release Notes
            </a>.
          </span>}
          closable
          onClose={() => setTimeout(calculateHeaderHeight, 500)}
        />
      )}
      {isMFASetupIncomplete ? (
        <>
          <div className='logo'>
            <img src="/imgs/kMap.svg" alt="kMap Logo" />
            <div>kMap</div>
          </div>
          {window.app?.user?.id && menu && (
            <Menu
              mode="horizontal"
              className="kmap-top-menu"
              items={[
                { // Account
                  key: 'account',
                  label: <div>{Language.get('header', 'ACCOUNT').toUpperCase()} <CaretDownOutlined /></div>,
                  children: [{
                    key: '/user/profile',
                    label: <Link to="/user/profile">{Language.get('header', 'PROFILE')}</Link>,
                  }, {
                    key: 'support',
                    label: <a href="mailto:support.genomics@vitrolife.com">{Language.get('header', 'SUPPORT')}</a>,
                  }, {
                    key: 'logout',
                    label: Language.get('header', 'LOGOUT'),
                    onClick: onSignedOut,
                  }],
                },
              ]}
              selectedKeys={activeKeys}
            />
          )}
        </>
      )
        : (
          <>
            <Link to="/">
              <div className='logo'>
                <img src="/imgs/kMap.svg" alt="kMap Logo" />
                <div>kMap</div>
              </div>
            </Link>
            {window.app?.user?.id && menu && <Menu
              mode="horizontal"
              className="kmap-top-menu"
              items={menu}
              selectedKeys={activeKeys}
            />}
          </>
        )
      }
    </Header>
  );
};
