// Importing react libs
import React from 'react';
import { Navigate } from 'react-router-dom';

// Importing app components
import {
  Accessioning,
  Beadchip,
  Case,
  CaseView,
  Dashboard,
  File,
  Log,
  Metrics,
  PlannerBuilder,
  PlannerList,
  ReportBuilder,
  ReportPreview,
  Terms,
} from 'containers';
import { Profile, List as UserList, UserForm } from 'components/user';
import { OrganizationForm, Setting as OrganizationSetting } from 'components/organization';
import { Search } from 'components/layout';

const RouteConfig = [
  { path: '/accessioning', element: Accessioning },
  { path: '/accessioning/:id', element: Accessioning },
  { path: '/beadchip', element: Beadchip },
  { path: '/case-list', element: Case },
  { path: '/case/:id/files', element: File },
  { path: '/case/:id', element: CaseView },
  { path: '/case/:id/analysis/:analysisId', element: CaseView },
  { path: '/case/:id/analysis/:analysisId/sample/:sampleId', element: CaseView },
  { path: '/case/:id/analysis/:analysisId/sample/:sampleId/chromosome/:chromosome', element: CaseView, },
  { path: '/metrics', element: Metrics },
  { path: '/organization/new', element: OrganizationForm, key: 'new-organization' },
  { path: '/organization/:id', element: OrganizationForm, key: 'edit-organization' },
  { path: '/organization/search', element: Search, key: 'global-search' },
  { path: '/organization/setting', element: OrganizationSetting, key: 'organization-setting' },
  { path: '/dashboard', element: Dashboard },
  { path: '/log', element: Log },
  { path: '/planner', element: PlannerList },
  { path: '/planner/new', element: PlannerBuilder, key: 'new-plate' },
  { path: '/planner/:id', element: PlannerBuilder, key: 'edit-plate' },
  { path: '/report/builder', element: ReportBuilder },
  { path: '/report/preview', element: ReportPreview },
  { path: '/terms', element: () => (<Terms hideActions />), key: 'terms' },
  { path: '/user', element: UserList, key: 'user' },
  { path: '/user/new', element: UserForm, key: 'new-user' },
  { path: '/user/:id', element: UserForm, key: 'edit-user' },
  { path: '/user/profile', element: Profile, key: 'user-profile' },
  { path: '*', element: () => (<Navigate to="/dashboard" />), key: 'catch-all' },
];

export default RouteConfig;
